import javascript from "./javascript.png";
import typescript from "./typescript.png";
import react from "./react.png";
import angular from "./angular.png";
import html from "./html.png";
import css from "./css.png";
import nodejs from "./nodejs.png";
import c from "./c.png";
import java from "./java.png";
import python from "./python.png";
import php from "./php.png";
import kotlin from "./kotlin.png";
import github from "./github.png";
import figma from "./figma.png";
import mysql from "./mysql.png";
import gcp from "./gcp.png";

export const StackIconJavascript = javascript;
export const StackIconTypescript = typescript;
export const StackIconReact = react;
export const StackIconAngular = angular;
export const StackIconHtml = html;
export const StackIconCss = css;
export const StackIconNodejs = nodejs;
export const StackIconC = c;
export const StackIconJava = java;
export const StackIconPython = python;
export const StackIconPHP = php;
export const StackIconKotlin = kotlin;
export const StackIconGithub = github;
export const StackIconFigma = figma;
export const StackIconMysql = mysql;
export const StackIconGCP = gcp;